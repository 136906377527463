@font-face{font-family:'Flexicar-Book';src:url('https://www.flexicar.es/_app/assets//fonts/Flexicar-Book.woff2') format('woff2');font-weight:500;font-style:normal;font-display:swap}

     body{font-family:'Flexicar-Book';color:#000; background: #ececec;}
      .cbody {

          background-color: #ececec;
      }
    .spinner{
         padding-top: 100px; background-color: #ececec; 
         color: #ff5d0e; 
         text-align:center; 
         height: 100vh; 
    }
      .hero{
        width: 90%; 
        object-fit: contain;
        display: block;
        margin: 0 auto
      }
      h1 {
          font-size: 26px;
      }

      h2 {
          font-size: 20px;
          margin: 40px 0 26px 0;
      }

      .logo {
          display: block;
          margin: 5px auto;
          width: 260px;
      }

      .flexicar-color {
          color: #ff5d0e;
      }
      .item-center{
      padding-left: 6.25%; padding-right: 6.25%; width: 87.5%;
			padding-top: 25px;
			padding-bottom: 5px;
      }
      
      .button {
  background-color: #ff5d0e;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px
}

      .bar {
          height: 4px;
          background-color: #ff5d0e;
      }

      .content {
          align-self: center;
          margin: 0px auto;
          width: 60%;
          background-color: #fff;
      }

      .content-box {
          margin: 15px 10%;
      }

      .orange-band {
          text-align: center;
          font-size: 25px;
          font-weight: 900;
          color: #fff;
          background-color: #ff5d0e;
          width: 100%;
      }

      .text {
          margin: 30px 0;
      }

      .list > p {
          margin: 1px;
      }

      .social-icons {
          text-align: center;
          padding-bottom: 20px;
      }

      footer {
          text-align: center;
          color: #555555;
      }

      .social-btn {
          width: 40px;
          margin: 0 5px;
      }

      .url {
          color: #fff !important;
          text-decoration: none;
          font-size: 20px;
          padding: 2px;
      }

      .a-legal {
          color: #555555;
      }

      .legal {
          margin: 0 15%;
          font-size: 9.5pt;
          font-family: "Times New Roman",serif;
          color: rgb(136,136,136);
      }
      #loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #fa681f56;
  border-radius: 50%;
  border-top-color: #ff5d0e;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
.inside-button{text-decoration: underline;
					color: #FFFFFF; font-family: sans-serif; font-size: 17px; font-weight: 400; line-height: 120%;}

      @media screen and (max-device-width: 700px),
      screen and (max-width: 700px) {
   

.inside-button{text-decoration: underline;
					color: #FFFFFF; font-family: sans-serif; font-size: 17px; font-weight: 400; line-height: 120%;}
          .content {
              align-self: center;
              margin: 20px auto;
              width: 90%;
              background-color: #fff;
          }

          .content-box {
              margin: 15px 5%;
          }

          h1 {
              font-size: 22px;
          }

          h2 {
              font-size: 17px;
              margin: 40px 0 26px 0;
          }

          .logo {
              display: block;
              margin: 5px auto;
              width: 160px;
          }

          p {
              font-size: 15px;
          }

          .social-btn {
              width: 30px;
              margin: 0 4px;
          }
          
          .legal {
              margin: 0 8%;
          }

      }